import React from 'react';
import classNames from 'classnames';

import Container from 'app/components/Container';
import SeasonList from 'app/components/SeasonList';

import seasonDescriptions from 'app/config/seasons';

import styles from './SeasonDecadeList.module.scss';

const getTitle = (key, seasons) => `${seasons[0].year} - ${key}9`;

const SeasonDecadeList = ({ data }) => (
    <div className={styles.root}>
        <Container>
            {data.map(({ key, seasons }, index) => (
                <article
                    className={classNames(styles.item, index % 2 === 1 ? styles.itemAlt : null)}
                    key={key}
                >
                    <div className={styles.inner}>
                        <div className={styles.content}>
                            <h3 className={styles.title}>{getTitle(key, seasons)}</h3>
                            <p className={styles.description}>{seasonDescriptions[key]}</p>
                        </div>
                        <div className={styles.list}>
                            <SeasonList data={seasons} />
                        </div>
                    </div>
                    <i className={styles.lines} />
                </article>
            ))}
        </Container>
    </div>
);

export default SeasonDecadeList;
