import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { getCurrentSeason } from 'app/services/season';

import Container from 'app/components/Container';
import Button, { BorderedButton } from 'app/components/Button';

import styles from './Introduction.module.scss';

const Introduction = () => {
    const subRef = useRef(null);

    const [isVisible, setVisible] = useState(false);

    const checkVisible = () => {
        setVisible(window.pageYOffset < 100);
        subRef.current = requestAnimationFrame(checkVisible);
    };

    const scrollToSeasons = () => {
        if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
        } else {
            window.scrollTo(0, window.innerHeight);
        }
    };

    useEffect(() => {
        subRef.current = requestAnimationFrame(checkVisible);

        return () => {
            cancelAnimationFrame(subRef.current);
        };
    }, []);

    return (
        <section className={styles.root}>
            <Container>
                <div className={classNames(styles.inner, isVisible ? styles.innerVisible : null)}>
                    <h2 className={styles.title}>Wot, No Mushy Peas?</h2>
                    <p className={styles.description}>
                        In 1873,{' '}
                        <a
                            href="http://www.gainsboroughtrinity.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Gainsborough Trinity Football Club
                        </a>{' '}
                        was established by Reverend George Langton Hodgkinson, the vicar at the Holy
                        Trinity Church. 146 years later, this project attempts to catalogue every
                        fixture played and goal scored by one of England’s oldest clubs.
                    </p>
                    <footer className={styles.cta}>
                        <Button to={`/${getCurrentSeason()}`}>View This Season</Button>
                        <BorderedButton onClick={scrollToSeasons}>View All Seasons</BorderedButton>
                    </footer>
                </div>
            </Container>
        </section>
    );
};

export default Introduction;
