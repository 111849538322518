/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'app/components/Link';

import styles from './Button.module.scss';

const Button = ({ to, isBordered, isDisabled, onClick, children }) => {
    const rootClass = classNames(
        styles.root,
        isBordered ? styles.rootBordered : null,
        isDisabled ? styles.rootDisabled : null
    );

    if (to) {
        return (
            <Link className={rootClass} onClick={(event) => onClick(event)} to={to}>
                {children}
            </Link>
        );
    }

    return (
        <button type="button" className={rootClass} onClick={(event) => onClick(event)}>
            {children}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.node.isRequired,
    isBordered: PropTypes.bool,
    isDisabled: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    isBordered: false,
    isDisabled: false,
    to: null,
    onClick: () => null,
};

export const BorderedButton = (props) => <Button isBordered {...props} />;

export default Button;
