import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'app/components/Link';

import styles from './SeasonListItem.module.scss';

const SeasonListItem = ({ data }) => {
    if (data.is_future) {
        return <span className={classNames(styles.root, styles.rootFuture)}>{data.name}</span>;
    }

    if (data.completion) {
        return (
            <Link className={styles.root} to={`/${data.year}`}>
                {data.name}
            </Link>
        );
    }

    if (data.is_undocumented) {
        return (
            <span className={classNames(styles.root, styles.rootUndocumented)}>
                {data.name}
                <small className={styles.meta}>(No fixtures recorded)</small>
            </span>
        );
    }

    return <span className={classNames(styles.root, styles.rootDisabled)}>{data.name}</span>;
};

SeasonListItem.propTypes = {
    data: PropTypes.shape({
        is_future: PropTypes.bool,
        is_undocumented: PropTypes.bool,
        name: PropTypes.string.isRequired,
        completion: PropTypes.number,
        year: PropTypes.number.isRequired,
    }),
};

SeasonListItem.defaultProps = {
    data: {
        is_future: false,
        is_undocumented: false,
        completion: null,
    },
};

export default SeasonListItem;
