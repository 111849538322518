/* eslint-disable max-len */

const seasons = {
    187: 'In 1873 the club is founded by Reverend Hodgkinson. Initially called the Holy Trinity Recreationist Society and then just Trinity Recreationists. It is thought that the Recreationist Society played their first match in September 1873. This is a friendly, as all games are in these days. Matches are played at Northolme, which is mainly used for cricket, and on other pitches around town including Ropery Road, Morton Terrace and Middlefield Lane.',
    188: 'The early years of the 1880s see the Trinity Recreationists play their first competitive matches. These are in the newly formed Lincolnshire Challenge Cup. The name changes to Gainsborough Trinity Recreationists and then finally to Gainsborough Trinity. This decade sees Trinity take part in the FA Cup for the first time and become a founder member of Midland League.',
    189: 'Trinity win the Midland League, and are runners-up twice. In 1896 the club is elected to the Football League. In the FA Cup, they reach the last 16.',
    190: 'Trinity spend the whole of this decade in Football League Division 2, playing such famous names as Manchester United, Manchester City, Chelsea and Woolwich Arsenal.',
    191: 'The club loses its place in the Football League and goes back to the Midland League where they have a relatively successful decade, apart from the very last season, when they finish bottom!',
    192: 'Trinity are in the Midland League for the entire decade and win it for the second time.',
    193: 'Trinity spend the decade in the Midland League. With World War II imminent, a special Midland League with just 8 teams is set up. This is abandoned after the threats to safety become too great.',
    194: 'Another decade in the Midland League, with Trinity being champions for the third time.',
    195: 'A rather uneventful 10 seasons in the Midland League.',
    196: 'After some disruption in non-league football, Trinity find themselves in the Yorkshire League for one season. They return to the Midland League and win it for the fourth time. Towards the end of the decade, Trinity are founder members of the Nothern Premier League.',
    197: 'A whole decade in the Northern Premier League.',
    198: 'Another decade in the Nothern Premier League. Towards the end of the decade this league in re-organised, and Trinity are in the Premier Division.',
    199: 'Ten seasons in the Nothern Premier League, Premier Division',
    200: 'Trinity start the decade in the Northern Premier League, Premier Division. A major re-arrangement of non-league football sees Trinity join the newly formed Conference, in the Northern Division.',
    201: 'Trinity continue in Conference North, which then changes name to National North. Towards the end of the decade, Trinity are relegated for the first time in their history, and move to the Northen Premier League, Premier Division.',
};

export default seasons;
