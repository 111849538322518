import React from 'react';
import PropTypes from 'prop-types';

import Scene from 'app/components/Scene';
import Introduction from 'app/components/Introduction';
import SeasonDecadeList from 'app/components/SeasonDecadeList';

const HomeScene = ({ pageContext }) => (
    <Scene>
        <Introduction />
        <SeasonDecadeList data={pageContext.data} />
    </Scene>
);

HomeScene.propTypes = {
    pageContext: PropTypes.shape({
        data: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.number.isRequired,
                seasons: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string.isRequired,
                        year: PropTypes.number.isRequired,
                    })
                ).isRequired,
            })
        ),
    }).isRequired,
};

export default HomeScene;
