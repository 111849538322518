import React from 'react';
import PropTypes from 'prop-types';

import SeasonListItem from './SeasonListItem';

import styles from './SeasonList.module.scss';

const DECADE_LENGTH = 10;
const LAST_YEAR_INDEX = 3;

const createPlaceholders = (data, year) => {
    let pYear = year;
    const len = DECADE_LENGTH - data.length;

    return [...Array(len >= 1 ? len : 1)].map(() => {
        const name = `${pYear - 1} - ${pYear}`;
        pYear += 1;

        return { year: pYear, name, is_future: true };
    });
};

const SeasonList = ({ data }) => {
    const year = data[0].year + data.length;
    const lastYear = data[data.length - 1].year.toString().charAt(LAST_YEAR_INDEX);

    const placeholders = lastYear === '9' ? [] : createPlaceholders(data, year);

    return (
        <ul className={styles.root}>
            {data.map((season) => (
                <li key={season.year} className={styles.item}>
                    <SeasonListItem data={season} />
                </li>
            ))}
            {placeholders.map((season) => (
                <li key={season.year} className={styles.item}>
                    <SeasonListItem data={season} />
                </li>
            ))}
        </ul>
    );
};

SeasonList.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            year: PropTypes.number,
        })
    ).isRequired,
};

export default SeasonList;
